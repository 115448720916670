/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://4jueankhxngbbczpi7steoucea.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zm5sozp46vhdlm43kqdkdyd3be",
    "aws_cognito_identity_pool_id": "us-east-1:0b6a195f-9c4a-48d0-89b9-95f19ec579c6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OzmrWG4GE",
    "aws_user_pools_web_client_id": "7vnt8pt5rq364r53qn1fs623qh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "persistence-storage-e468cc0a20708-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
