//Exercise.js
//import '@aws-amplify/ui-react/styles.css';


import { DataStore } from "aws-amplify/datastore";
import { Exercise as Exercises} from "../models";

import {
  ExercicioCollection
 } from '../ui-components';

 const currentUrl = window.location.href;

  let taskId = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
  
  
  const items = await DataStore.query(Exercises, (c) => c.taskID.eq(taskId));
  console.log("taskID:" + taskId);
  console.log(items);

function Exercise({ user }) {
  

  return (
    <>
      <ExercicioCollection
        items={items} 
      />
    </>
  );
}

export default Exercise;