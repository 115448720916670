// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TaskType = {
  "EXERCISE": "EXERCISE",
  "KNOWLEDGE": "KNOWLEDGE"
};

const { Exercise, Task } = initSchema(schema);

export {
  Exercise,
  Task,
  TaskType
};