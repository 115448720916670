// routes.js
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import Exercise from './exercise/Exercise';

import { withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import './styles.css';

import {
  MenuSuperior
 } from './ui-components';


function Router(signOut, user) {
  return (
    <BrowserRouter>
      <MenuSuperior width={'100%'}/>
      
      <Routes>
        <Route path="" element={<App />} />
        <Route path="/" element={<App />} />
        <Route path="/gym/:taskId" element={<Exercise />} />  
      </Routes>
    </BrowserRouter>
  );
}

export default withAuthenticator(Router);
