//App.js
import { Amplify } from 'aws-amplify';


import config from './amplifyconfiguration.json';



import {
  TaskCollection
 } from './ui-components';

 
Amplify.configure(config);

function App() {
  return (
    <>
      <TaskCollection width={'100%'}  />
    </>
  );
}
//<h1>Hello {user.signInDetails.loginId}</h1>
export default App;